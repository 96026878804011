import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import {
  // HiOutlineAtSymbol,
  HiEye,
  HiEyeOff
 } from 'react-icons/hi'
import {
  LiaLockSolid,
  LiaEnvelopeSolid,
  // LiaUserSolid,
  LiaTimesCircleSolid,
  LiaHomeSolid,
  LiaFontAwesomeFlag,
  LiaPhoneSolid,
 } from 'react-icons/lia'
import axios from "axios"
import Swal from "sweetalert2"
import { useDispatch, useSelector } from "react-redux"
import { setData, setUser } from "../redux/authSlice"
import { setCity, setCode } from "../redux/filterSlice"
import { setShowNav } from "../redux/filterSlice"
import LoadingThreeDots from "./LoadingThreeDots"
 
const ModalLoginRegister = (props) => {
  const {type, onShowModal} = props
  const dispatch = useDispatch()
  const filter = useSelector(state => state.filter)

  const [isLoading, setLoading] = useState(false)
  const [isType, setType] = useState(type)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isAcceptTerms, setAcceptTerms] = useState(false)
  const [isNewsletter, setNewsletter] = useState(true)
  const [state, setState] = useState({
    fullname: '',
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    address: '',
    country: 1,
    city: '',
    local: '',
    code: '+234',
    number: '',
    listCity: filter?.city,
    listCode: filter?.code,
    listLocal: []
  })

  useEffect(() => {
    const fetchAll = () => {
      Promise.all([
        filter?.city?.length === 0 && getApi('CITY', '/api/category/states'),
        filter?.code?.length === 0 && getApi('CODE', '/api/master/country/index'),
      ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;    
    const headers = {
      'Content-Type': 'application/json',
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
             if(type === 'CITY') {
              dispatch(setCity(results))
              setState(prevState => {
                return{
                  ...prevState,
                  listCity: results
                }
              })
            }else {
              dispatch(setCode(results))
              setState(prevState => {
                return{
                  ...prevState,
                  listCode: results
                }
              })
            }
            // setLoading(false)
          }, 500);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        setLoading(false)
      });
  }  

  const handleType = (value) => {
    setState(prevState => {
      return {
        ...prevState,
        fullname: '',
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
      }
    })
    setType(value)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState(prevState => {
      let local = name === 'city' ? 
        prevState.listCity.filter(f => f.id === parseInt(value))[0].local_governments 
        : prevState.listLocal
      return {
        ...prevState,
        [name]: value,
        listLocal: local,
      }
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleLogin()
  };

  const handleLogin = () => {
    if (state.email === '') return ShowAlert('Email Required!')
    if (state.password === '') return ShowAlert('Password Required!')
    setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/login';    
    const headers = {
      'Content-Type': 'application/json',
    };
    const bodyParams = {
      email: state.email,
      password: state.password,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            dispatch(setData(results))
            getUser(results.access_token)
            // setLoading(false)
            // onShowModal(false)
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { results } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: results.title,
          text: results.error,
          confirmButtonColor: '#B38C32'
        })
        setLoading(false)
      });
  }

  const handleRegister = () => {
    if (state.firstname === '') return ShowAlert('First name is required!', 'Enter first name')
    if (state.lastname === '') return ShowAlert('Last name is required!', 'Enter last name')
    // if (state.username === '') return ShowAlert('Username required!')
    if (state.email === '') return ShowAlert('Email is required!')
    if (state.password === '') return ShowAlert('Password is required!')
    if (state.city === '') return ShowAlert('State is required!', 'Enter State')
    if (state.local === '') return ShowAlert('Local government is required!', 'Enter local government')
    if (state.number === '') return ShowAlert('Mobile number is required!', 'Enter mobile number')
    // if (state.number.length > 11) return ShowAlert('Max 11 Mobile Number!')
    if (!isAcceptTerms) return ShowAlert('Please accept terms & conditions!')

    setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/register';
    const headers = {
      'Content-Type': 'application/json',
    };
    const bodyParams = {
      full_name: state.firstname+' '+state.lastname,
      // user_name: state.username,
      email: state.email,
      password: state.password,
      phone_code: state.code,
      phone_number: state.number,
      state_id: state.city,
      local_government_id: state.local,
      address: state.address,
      subscribe: isNewsletter ? 1 : 0,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { messages } = response.data.response            
            // dispatch(setData(results))
            setLoading(false)
            onShowModal(false)
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
          }, 1000);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Invalid input',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
        setLoading(false)
      });
  }

  const getUser = (token) => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/me';    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            setState({
              email: '',
              password: ''
            })
            const { results } = response.data.response
            dispatch(setUser(results))
            dispatch(setShowNav(false))
            // setLoading(false)
            // onShowModal(false)
            window.location.reload()
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        setLoading(false)
      });
  }

  const handleForgot = () => {
    if (state.email === '') return ShowAlert('Email required!')

    setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/forgot_password';    
    const headers = {
      'Content-Type': 'application/json',
    };
    const bodyParams = {
      email: state.email,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { messages } = response.data.response
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: messages,
            confirmButtonColor: '#B38C32'
          })
          setLoading(false)
          onShowModal(false)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
        setLoading(false)
      });
  }

  const handleReset = () => {
    if (state.password === '') return ShowAlert('Password required!')
    if (state.confirmPassword === '') return ShowAlert('Confirm Password required!')

    setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/reset_password';    
    const headers = {
      'Content-Type': 'application/json',
    };
    const bodyParams = {
      new_password: state.password,
      new_password_confirmation: state.confirmPassword,
      token: props?.resetPass?.token,
      id: props?.resetPass?.id,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: messages,
              confirmButtonColor: '#B38C32'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.replace(results.authorization_url.url)
                setLoading(false)
              }
            })
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
        setLoading(false)
      });
  }

  const ShowAlert = (text, title) => {
    Swal.fire({
      icon: 'warning',
      title: !title ? 'Warning' : title,
      text: text,
      confirmButtonColor: '#B38C32'
    })
  }

  const LoadingIndicator = () => <LoadingThreeDots size={'20'} />
  
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[400px] md:w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex relative ">
            <LiaTimesCircleSolid 
              className=" absolute text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            <div className=" w-1/3 bg-[#366C38] items-center hidden md:flex justify-center rounded-l">
              <img 
                alt="logo" 
                src={process.env.PUBLIC_URL+'/assets/Logo.png'} 
                // src="https://paseero.com/wp-content/uploads/2023/03/paseero-high-resolution-logo-color-on-transparent-background.png" 
                className=" w-60 px-4"
              />
            </div>
            {isType === 1 ? (
              <div className=" w-full md:w-2/3 flex flex-col justify-center p-4 lg:p-8 h-[450px]">
                <div className=" text-center text-lg lg:text-2xl text-[#B38C32] font-bold">
                  Member Login
                </div>
                <div className=" flex flex-col space-y-3 mt-12">
                  <div className=" text-sm">Don't have an account? <span className=" text-blue-500 hover:text-[#B38C32] cursor-pointer" onClick={() => handleType(2)} >Sign Up</span></div>
                  <div className=" relative w-full">
                    <input 
                      type={'email'} 
                      autoComplete="off" 
                      name="email" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Email"
                      value={state.email}
                      onChange={handleChange}
                    />
                    <LiaEnvelopeSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div>
                  <div className=" relative w-full">
                    <input 
                      type={showPassword ? 'text' : 'password'} 
                      autoComplete="off" 
                      name="password" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Password"
                      value={state.password}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <LiaLockSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                    {showPassword ? (
                      <HiEyeOff className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowPassword(false)} />
                    ): (
                      <HiEye className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowPassword(true)} />
                    )}
                  </div>
                  <button 
                    className=" p-3 text-sm lg:text-base justify-center flex font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                    onClick={handleLogin}
                  >
                    {!isLoading ? 
                      'Sign In' 
                      : <LoadingIndicator />
                    }
                  </button>
                  <div className=" flex items-center justify-between">
                    <div className=" flex items-center space-x-2">
                      <input type={'checkbox'} id="checkbox-remember"  />
                      <label htmlFor="checkbox-remember" className=" text-sm">Remember me </label>
                    </div>
                    <div 
                      className=" text-blue-500 text-sm hover:text-[#B38C32] cursor-pointer"
                      onClick={() => setType(3)}
                    >
                      Lost password?
                    </div>
                  </div>
                </div>
              </div>
            ): isType === 2 ? (
              <div className=" w-full md:w-2/3 flex flex-col justify-center p-4 lg:p-8">
                <div className=" text-center text-lg lg:text-2xl text-[#B38C32] font-bold">
                  Sign Up
                </div>
                <div className=" flex flex-col space-y-3 mt-12">
                  <div className=" text-sm">Already a member? <span className=" text-blue-500 hover:text-[#B38C32] cursor-pointer" onClick={() => handleType(1)} >Sign In</span></div>
                  {/* <div className=" relative w-full">
                    <input 
                      type={'text'} 
                      autoComplete="off" 
                      name="fullname" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Fullname*" 
                      value={state.fullname}
                      onChange={handleChange}
                    />
                    <LiaUserSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div> */}
                  <div className=" relative w-full space-x-3 flex items-center">
                    <input 
                      type={'text'} 
                      autoComplete="off" 
                      name="firstname" 
                      className=" p-3 w-1/2 rounded border focus:outline-none text-sm" 
                      placeholder="First name*" 
                      value={state.firstname}
                      onChange={handleChange}
                    />
                    <input 
                      type={'text'} 
                      autoComplete="off" 
                      name="lastname" 
                      className=" p-3 w-1/2 rounded border focus:outline-none text-sm" 
                      placeholder="Last name*" 
                      value={state.lastname}
                      onChange={handleChange}
                    />
                    {/* <LiaUserSolid className=" absolute top-3 left-2 text-gray-400 text-xl" /> */}
                  </div>
                  {/* <div className=" relative w-full">
                    <input 
                      type={'text'} 
                      autoComplete="off" 
                      name="username"
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Username*" 
                      value={state.username}
                      onChange={handleChange}
                    />
                    <HiOutlineAtSymbol className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div> */}
                  <div className=" relative w-full">
                    <input 
                    type={'email'} 
                    autoComplete="off" 
                    name="email" 
                    className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                    placeholder="Email*" 
                    value={state.email}
                    onChange={handleChange}
                  />
                    <LiaEnvelopeSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div>
                  <div className=" relative w-full">
                    <input 
                      type={showPassword ? 'text' : 'password'} 
                      autoComplete="off" 
                      name="password" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Password* (Min 7 char, capital letter, numeric)"
                      value={state.password}
                      onChange={handleChange}
                    />
                    <LiaLockSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                    {showPassword ? (
                      <HiEyeOff className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowPassword(false)} />
                    ): (
                      <HiEye className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowPassword(true)} />
                    )}
                  </div>
                  <div className=" relative w-full">
                    <input 
                    type={'text'} 
                    autoComplete="off" 
                    className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                    placeholder="eg. District, Street, House Number"
                    name="address" 
                    value={state.address}
                    onChange={handleChange}
                  />
                    <LiaHomeSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div>
                  <div className=" relative w-full">
                    <select
                      className=" p-3 px-6 w-full rounded border focus:outline-none text-sm" 
                      defaultValue={1}
                    >
                      <option value={1} >Nigeria*</option>
                    </select>
                    <LiaFontAwesomeFlag className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div>
                  <div className=" relative w-full space-x-2 flex items-center">
                    <select
                      className=" p-3 px-1 w-1/2 rounded border focus:outline-none text-sm" 
                      name="city"
                      value={state.city}
                      onChange={handleChange}
                    >
                      <option disabled value=''>State*</option>
                      {state.listCity?.map(row => (
                        <option 
                          key={row.id}
                          value={row.id} 
                        >
                          {row.name}
                        </option>
                      ))}
                    </select>
                    <select
                      className=" p-3 px-1 w-1/2 rounded border focus:outline-none text-sm" 
                      name="local"
                      value={state.local}
                      onChange={handleChange}
                    >
                      <option disabled value=''>LGA*</option>
                      {state.listLocal?.map(row => (
                        <option 
                          key={row.id}
                          value={row.id} 
                        >
                          {row.name}
                        </option>
                      ))}
                    </select>
                    {/* <LiaFontAwesomeFlag className=" absolute top-3 left-2 text-gray-400 text-xl" /> */}
                  </div>
                  {/* <div className=" relative w-full">
                    <select
                      className=" p-3 w-full rounded border focus:outline-none text-sm" 
                      name="code"
                      value={state.code}
                      onChange={handleChange}
                    >
                      <option value={'+234'}>🇳🇬 Nigeria <span className=" text-gray-300">(+234)</span></option>
                      <option value={'+227'}>🇳🇪 Niger <span className=" text-gray-300">(+227)</span></option>
                      <option value={'+505'}>🇳🇮 Nicaragua <span className=" text-gray-300">(+505)</span></option>
                      <option value={'+638'}>🇳🇺 Niue <span className=" text-gray-300">(+683)</span></option>
                      <option value={'+686723'}>🇳🇫 Norfolk Island <span className=" text-gray-300">(+686723)</span></option>
                    </select>
                  </div> */}
                  <div className="relative w-full ">
                    <input 
                      className=" text-sm p-3 px-8 w-full rounded border focus:outline-none" 
                      placeholder="Mobile number*" 
                      name="number"
                      value={state.number}
                      onChange={handleChange}
                    />
                    <LiaPhoneSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div>
                  <div className=" flex flex-col space-y-2">
                    <div 
                      className=" flex items-center space-x-2" 
                      // onClick={() => setAcceptTerms(!isAcceptTerms)}
                    >
                      <input 
                        type={'checkbox'} 
                        id="checkbox-newsletter"
                        checked={isNewsletter}
                        onChange={(e) => setNewsletter(e.target.checked)}
                      />
                      <label htmlFor="checkbox-newsletter" className=" text-sm cursor-pointer">Subscribe to newsletter</label>
                    </div>
                    <div 
                      className=" flex items-center space-x-2" 
                      // onClick={() => setAcceptTerms(!isAcceptTerms)}
                    >
                      <input 
                        type={'checkbox'} 
                        id="checkbox-terms"
                        checked={isAcceptTerms}
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                      />
                      <label htmlFor="checkbox-terms" className=" text-sm cursor-pointer">Accept</label>
                      <Link to={'/terms-conditions'} className=" text-sm text-blue-500 hover:text-[#B38C32]"> Terms & Conditions</Link>
                    </div>
                  </div>
                  <button
                    className=" flex text-sm lg:text-base justify-center p-3 font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                    onClick={handleRegister}
                  >
                    {!isLoading ? 
                      'Create Account' 
                      : <LoadingIndicator />
                    }
                  </button>
                </div>
              </div>
            ): isType === 3 ? (
              <div className=" w-full md:w-2/3 flex flex-col justify-center p-8 h-[450px]">
                <div className=" text-center text-2xl text-[#B38C32] font-bold">
                  Password Reset
                </div>
                <div className=" flex flex-col space-y-3 mt-12">
                  <div className=" text-sm">Enter your account email address below.</div>
                  <div className=" relative w-full">
                    <input 
                      type={'email'} 
                      autoComplete="off" 
                      name="email" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Email"
                      value={state.email}
                      onChange={handleChange}
                    />
                    <LiaEnvelopeSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                  </div>
                  <button 
                    className=" p-3 justify-center flex font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                    onClick={handleForgot}
                  >
                    {!isLoading ? 
                      'Continue' 
                      : <LoadingIndicator />
                    }
                  </button>
                  <div className=" flex items-center justify-between">
                    <div className=" text-sm">
                      Not registered? 
                      <span className=" text-blue-500 hover:text-[#B38C32] cursor-pointer" onClick={() => handleType(2)} > Sign Up</span>
                    </div>
                    <div 
                      className=" text-blue-500 text-sm hover:text-[#B38C32] cursor-pointer"
                      onClick={() => setType(1)}
                    >
                      Sign In
                    </div>
                  </div>
                </div>
              </div>
            ): (
              <div className=" w-full md:w-2/3 flex flex-col justify-center p-8 h-[450px]">
                <div className=" text-center text-2xl text-[#B38C32] font-bold">
                  Create New Password
                </div>
                <div className=" flex flex-col space-y-3 mt-12">
                  <div className=" relative w-full">
                    <input 
                      type={showPassword ? 'text' : 'password'} 
                      autoComplete="off" 
                      name="password" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="New password"
                      value={state.password}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <LiaLockSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                    {showPassword ? (
                      <HiEyeOff className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowPassword(false)} />
                    ): (
                      <HiEye className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowPassword(true)} />
                    )}
                  </div>
                  <div className=" relative w-full">
                    <input 
                      type={showConfirmPassword ? 'text' : 'password'} 
                      autoComplete="off" 
                      name="confirmPassword" 
                      className=" p-3 px-8 w-full rounded border focus:outline-none text-sm" 
                      placeholder="Confirm new password"
                      value={state.confirmPassword}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <LiaLockSolid className=" absolute top-3 left-2 text-gray-400 text-xl" />
                    {showConfirmPassword ? (
                      <HiEyeOff className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowConfirmPassword(false)} />
                    ): (
                      <HiEye className=" absolute top-3 right-3 text-gray-400 text-xl cursor-pointer" onClick={() => setShowConfirmPassword(true)} />
                    )}
                  </div>
                  <button 
                    className=" p-3 justify-center flex font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                    onClick={handleReset}
                  >
                    {!isLoading ? 
                      'Submit' 
                      : <LoadingIndicator />
                    }
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalLoginRegister